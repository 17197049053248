import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';


const Contact = () => {
    const [focusField, setFocusField] = useState("");
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [clickedSubmit, setClickedSubmit] = useState(false)

    const [messageToSend, setMessageToSend] = useState('')
    const handleFocus = (field) => {
        setFocusField(field);
    };

    const handleBlur = () => {
        setFocusField("");
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };



    const [messageColour, setMessageColour] = useState('text-green-500');

    const handleSubmit = (e) => {
        e.preventDefault();
       
        const templateParams = {
            name: formData.name,
            email: formData.email,
            message: formData.message
        };

        if (!formData.name || !formData.email || !formData.message) {
            setMessageColour('text-red-500')
            setMessageToSend('All fields are required');

           
            return;
        }

        if (!validateEmail(formData.email)) {
            setMessageColour('text-red-500');
            setMessageToSend('Invalid email address');
            return;
        }

        if (!clickedSubmit) {
            emailjs.send(
                process.env.REACT_APP_SERVICE_ID,
                process.env.REACT_APP_TEMPLATE_ID,
                templateParams,
                process.env.REACT_APP_USER_ID
            )
                .then((response) => {
                    setMessageColour('text-green-700')
                    setMessageToSend('Message sent successfully!')
                    setTimeout(() => {
                        setMessageToSend('');
                    }, 2000);

                    setFormData({
                        name: '',
                        email: '',
                        message: ''
                    });
                }, (error) => {

                });
            setClickedSubmit(true)
            setTimeout(() => {
                setClickedSubmit(false)
            }, 1000);
        }



    };

    return (
        <div id="contact" className="w-full h-full py-10 text-white">
            <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
                <div className="pb-8">
                    <p className="text-4xl font-ubuntu font-bold text-black dark:text-white inline border-b-4 border-purple-500 dark:border-gray-800">
                        Contact Me
                    </p>
                    <div className="mt-10 w-auto p-4 mx-auto shadow-md dark:bg-gray-800 rounded-lg">
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col md:justify-start md:items-start">
                                <div className="w-full flex-col flex md:flex md:flex-row">
                                    <div className="w-full md:mr-4">
                                        <div className={`flex  transition-all duration-500 ease-in-out`}>
                                            <h2 className={`font-ubuntu text-black dark:text-white ${focusField === 'name' ? 'font-bold' : ''}`}>
                                                Name
                                            </h2>
                                            <h2 className={`ml-1 font-ubuntu text-purple-700 dark:text-red-700 font-bold ${focusField === 'email' ? 'font-bold' : ''}`}>
                                                *
                                            </h2>
                                        </div>
                                        <input
                                            className="text-black font-ubuntu font-light w-full bg-gray-200 dark:bg-white mb-4 p-2 rounded duration-300 focus:scale-101"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            onFocus={() => handleFocus('name')}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                    <div className="w-full md:ml-4">
                                        <div className={`flex transition-all duration-500 ease-in-out`}>
                                            <h2 className={`font-ubuntu text-black dark:text-white ${focusField === 'email' ? 'font-bold' : ''}`}>
                                                Email
                                            </h2>
                                            <h2 className={`ml-1 font-ubuntu text-purple-700 dark:text-red-700 font-bold ${focusField === 'email' ? 'font-bold' : ''}`}>
                                                *
                                            </h2>
                                        </div>
                                        <input
                                            className="text-black font-ubuntu font-light w-full bg-gray-200 dark:bg-white mb-4 p-2 rounded duration-300 focus:scale-101"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            onFocus={() => handleFocus('email')}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </div>
                                <div className={`flex transition-all duration-500 ease-in-out`}>
                                    <h2 className={`mb-1 font-ubuntu text-black dark:text-white ${focusField === 'message' ? 'font-bold' : ''}`}>
                                        Message
                                    </h2>
                                    <h2 className={`ml-1 font-ubuntu text-purple-700 dark:text-red-700 font-bold ${focusField === 'message' ? 'font-bold' : ''}`}>
                                        *
                                    </h2>
                                </div>
                                <textarea
                                    className="text-black bg-gray-200 font-ubuntu font-light dark:bg-white mb-4 p-2 h-auto rounded w-full duration-300 focus:scale-101"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    onFocus={() => handleFocus('message')}
                                    onBlur={handleBlur}
                                />
                                <div className='flex flex-row w-full '>
                                    <button type="submit" className='bg-purple-700 dark:bg-purple-800 text-white font-ubuntu font-medium py-2 px-4 rounded-full duration-200 hover:bg-purple-800 hover:dark:bg-purple-900 hover:scale-105'>
                                        {clickedSubmit ? 'Send' : 'Send'}
                                    </button>
                                   
                                    <p className={`${messageColour} flex-grow text-center mr-14 md:mr-16 mt-2 font-ubuntu font-light`}>{messageToSend}</p>
                                </div>

                            </div>
                        </form>



                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
