import React, { useState, useEffect } from 'react';
import { FaSun, FaMoon, FaBars, FaTimes } from 'react-icons/fa';

const Navbar = ({ darkMode, toggleDarkMode }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const clickedHamburger = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [menuOpen]);


  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    setMenuOpen(false);
  };

  const links = [
    { id: 1, link: 'home', onClick: () => scrollToSection('home') },
    { id: 2, link: 'about', onClick: () => scrollToSection('about') },
    { id: 3, link: 'portfolio', onClick: () => scrollToSection('portfolio') },
    { id: 4, link: 'experience', onClick: () => scrollToSection('experience') },
    { id: 5, link: 'contact', onClick: () => scrollToSection('contact') }
  ];

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className='flex justify-between items-center w-full h-20 px-4 bg-purple-700 text-white fixed dark:bg-black dark:text-white shadow-md font-raleway'>
      <div
        className=' w-40 flex ' 
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <h1
          className='text-3xl font-ubuntu font-normal ml-1 cursor-pointer hover:scale-105 duration-200'
        >
          {isHovered ? 'Juxta J' : 'Josh Bates'}
        </h1>
      </div>
      <div className='hidden md:flex items-center'>
        <ul className='flex'>
          {links.map((link) => (
            <li
              key={link.id}
              className='px-3 cursor-pointer capitalize font-ubuntu font-light hover:font-normal text-black  dark:text-white hover:scale-105 duration-200 ml-1'
              onClick={link.onClick ? link.onClick : () => { }}
            >
              {link.link}
            </li>
          ))}
        </ul>
        <label className="switch ml-2">
          <input
            type="checkbox"
            checked={darkMode}
            onChange={toggleDarkMode}
          />
          <span className="slider"></span>
        </label>

      </div>


      <div onClick={clickedHamburger} className={`flex cursor-pointer ${menuOpen ? 'text-black' : 'text-white'} dark:text-white items-center mr-2 md:hidden hover:scale-105 duration-200 z-20`}>
        {menuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
      </div>


      {menuOpen && (
        <ul className='overflow-hidden z-10  absolute flex flex-col justify-center items-center top-0 left-0 w-full h-screen bg-gradient-to-b from-white to-white dark:from-black dark:to-gray-900 '>
          {links.map((link) => (
            <li
              key={link.id}
              className='px-4 cursor-pointer capitalize mt-8 text-2xl  font-ubuntu font-light  text-black dark:text-white hover:scale-105 duration-200'
              onClick={link.onClick ? link.onClick : () => setMenuOpen(false)}
            >
              {link.link}
            </li>
          ))}
          <label className="switch mt-8 cursor-pointer hover:scale-103 duration-300">
            <input
              type="checkbox"
              checked={darkMode}
              onChange={toggleDarkMode}
            />
            <span className="slider"></span>
          </label>
        </ul>
      )}
    </div>
  );
};

export default Navbar;
