import React from "react";

import pythonImage from '../Images/python.png'; 
import nodejsImage from '../Images/nodejs.png'; 
import expressImage from '../Images/expressjs.png'; 
import reactImage from '../Images/React.webp'; 
import postgresImage from '../Images/postgres.png';
import jsImage from '../Images/javascript.png';
import javaImage from '../Images/java.png';
import gitImage from '../Images/github.png';
import gitLabImage from '../Images/gitlab.png';

const Experience = () => {
  const languages = [
    {
      id: 1,
      src: pythonImage,
      title: "Python",
      style: "shadow-yellow-500/50",
    },
    {
      id: 2,
      src: jsImage,
      title: "JavaScript",
      style: "shadow-yellow-500/50",
    },
    {
      id: 3,
      src: javaImage,
      title: "Java",
      style: "shadow-red-500/50",
    },
    
  ];

  const frameworks = [
    {
      id: 4,
      src: nodejsImage,
      title: "Node.js",
      style: "shadow-green-500/50",
    },
    {
      id: 5,
      src: expressImage,
      title: "Express.js",
      style: "shadow-gray-500/50",
    },
    {
      id: 6,
      src: reactImage,
      title: "React.js",
      style: "shadow-blue-500/50",
    },
  ];

  const databases = [
    {
      id: 7,
      src: postgresImage,
      title: "PostgreSQL",
      style: "shadow-blue-500/50",
    },
  ];

  const tools = [
    {
      id: 8,
      src: gitImage,
      title: "Git",
      style: "shadow-orange-500/50",
    },
    {
        id: 9,
        src: gitLabImage,
        title: "GitLab",
        style: "shadow-orange-500/50",
      },
  ];

  return (
    <div id="experience" className="w-full h-full py-10">
      <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
        <div>
          <p className="text-4xl font-ubuntu font-bold text-black dark:text-white inline border-b-4 border-purple-500 dark:border-gray-800">
            Experience
          </p>
          <p className="py-6 text-gray-800 dark:text-white font-ubuntu font-light">
            Below are some of the technologies I have worked with:
          </p>
        </div>

        <div className="mb-8">
          <h3 className="text-2xl font-ubuntu font-semibold text-black dark:text-white mb-4">
            Languages
          </h3>
          <div className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-8 text-center py-8 px-4 sm:px-0">
            {languages.map(({ id, src, title, style }) => (
              <div
                key={id}
                className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}
              >
                <img src={src} alt={title} className="w-20 mx-auto" />
                <p className="mt-4 font-ubuntu text-black dark:text-white">{title}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="mb-8">
          <h3 className="text-2xl font-ubuntu font-semibold text-black dark:text-white mb-4">
            Frameworks
          </h3>
          <div className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-8 text-center py-8 px-4 sm:px-0">
            {frameworks.map(({ id, src, title, style }) => (
              <div
                key={id}
                className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}
              >
                <img src={src} alt={title} className="w-20 mx-auto" />
                <p className="mt-4 font-ubuntu text-black dark:text-white">{title}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="mb-8">
          <h3 className="text-2xl font-ubuntu font-semibold text-black dark:text-white mb-4">
            Databases
          </h3>
          <div className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-8 text-center py-8 px-4 sm:px-0">
            {databases.map(({ id, src, title, style }) => (
              <div
                key={id}
                className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}
              >
                <img src={src} alt={title} className="w-20 mx-auto" />
                <p className="mt-4 font-ubuntu text-black dark:text-white">{title}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="mb-8">
          <h3 className="text-2xl font-ubuntu font-semibold text-black dark:text-white mb-4">
            Tools
          </h3>
          <div className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-8 text-center py-8 px-4 sm:px-0">
            {tools.map(({ id, src, title, style }) => (
              <div
                key={id}
                className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}
              >
                <img src={src} alt={title} className="w-20 mx-auto" />
                <p className="mt-4 font-ubuntu text-black dark:text-white">{title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
