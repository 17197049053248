import React from "react";

const About = () => {
  return (
    <div id="about" className=" w-full h-full py-10 text-white">
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-ubuntu font-bold text-black dark:text-white inline border-b-4 border-purple-500 dark:border-gray-800">
            About
          </p>
        </div>

        <p className="text-xl mt-10 text-black dark:text-white font-ubuntu font-light">
        I graduated in July 2024 from the University of Birmingham with a BSc in Computer Science. I have a deep passion for Software Engineering and enjoy working on innovative projects. My academic journey has equipped me with a solid foundation in various programming languages and technologies, and I am eager to apply my skills to solve real-world challenges.
        </p>


        <p className="text-xl text-black mt-2 dark:text-white font-ubuntu font-light">
        My interest in computer science and programming began during my years in secondary school and continued through college. Throughout this time, I dedicated myself to learning various programming languages and exploring different aspects of software development. This early passion for technology laid the foundation for my academic and professional journey in the field of Computer Science.
        </p>
      </div>
    </div>
  );
};

export default About;
