import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Homepage from './Components/Homepage';
import Navbar from './Components/Navbar';
import React, { useState, useEffect } from 'react';
import Portfolio from './Components/Portfolio';
import Experience from './Components/Experience'
import About from './Components/About'
import Contact from './Components/Contact';

function App() {
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className='bg-gradient-to-b from-white to-white dark:from-black dark:to-gray-900 md:min-h-screen '>
      <Navbar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      <Homepage />
      <About/>
      <Portfolio />
      <Experience/>
      <Contact/>
    </div>
  );
}

export default App;
