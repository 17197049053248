import React from 'react';
import '../index.css';
import CoverPhoto from '../Images/CoverPhoto.png';

const Homepage = () => {
  const scrollToPortfolio = () => {
    const portfolioSection = document.getElementById('portfolio');
    if (portfolioSection) {
      portfolioSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div id='home' className='  min-h-screen w-full flex flex-col py-20 justify-center'>
      <div className='max-w-screen-2xl mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row'>
        <div className='w-full flex flex-col items-center md:items-start md:px-10 mb-10 md:mb-0'>
          <h1 className='text-4xl md:text-5xl font-ubuntu font-bold text-black dark:text-white mt-10 md:mt-0'>
            Hi, I'm Josh
          </h1>
          <p className='text-black font-ubuntu font-light dark:text-white text-center md:text-left mt-4'>
            I am a recent Computer Science graduate with a strong passion for Software Engineering. I enjoy developing innovative software solutions and constantly strive to improve my skills and knowledge in various programming languages and technologies. I am excited about the opportunities to work on challenging projects that make a meaningful impact.
          </p>
          <div className='flex justify-center md:justify-start mt-6'>
            
            <button
               onClick={scrollToPortfolio}
              className='bg-purple-700 dark:bg-purple-800 text-white font-ubuntu font-medium py-2 px-4 rounded-full duration-200 hover:bg-purple-800 hover:dark:bg-purple-900 hover:scale-105'
            >
              Portfolio
            </button>
          </div>
        </div>
        <div className='w-full flex items-center justify-center md:justify-end md:px-10'>
          <img
            className='rounded-2xl object-cover h-72 md:h-96 shadow-lg transition-transform duration-300 '
            src={CoverPhoto}
            alt="Cover"
          />
        </div>
      </div>
    </div>
  );
};

export default Homepage;
