import React, { useState, useEffect } from 'react';
import bytebudsImage from '../Images/bytebuds.png'; 
import barberImage from '../Images/barber.png'; 
import jaImage from '../Images/juxtasadv.png';
import juxtajifyImage  from '../Images/juxtajify.png';




const Portfolio = () => {
    const [selectedProject, setSelectedProject] = useState(null);

    const portfolios = [
        {
            id: 1,
            src: barberImage,
            name: 'Barber Booking Template',
            date: 'July 2024',
            technologies: '[ASP.NET, React.js, SQL Server]',
            link: '',
            code: '',
            description: 'WIP | Links will be added soon'
        },
        {
            id: 2,
            src: bytebudsImage,
            name: 'Byte Buds',
            date: '08/04/2024',
            technologies: '[Node.js, Express.js, React.js, PostgreSQL]',
            link: '/bytebuds',
            code: 'https://github.com/joshbates03/Byte-Buds',
            description: 'Byte Buds originated from my BSc thesis in Computer Science at the University of Birmingham. It was driven by a desire to address common challenges students encounter while learning to code. The platform aims to provide a user-friendly solution that enhances the learning experience. By combining academic knowledge with practical application, Byte Buds offers a valuable resource for learners of all levels.'
        },
        {
            id: 3,
            src: juxtajifyImage,
            name: 'Juxtajify',
            date: 'Summer 2023',
            technologies: '[Node.js, Express.js, React.js, PostgreSQL]',
            link: '/juxtajify',
            code: 'https://github.com/joshbates03/Juxtajify',
            description: 'Juxtajify is a quick URL shortener project I developed to serve as a hands-on experiment to familiarise myself with the tech stack that I would later use in my final year project, Byte Buds. Juxtajify provided a practical way to deepen my understanding of full-stack development, laying the groundwork for more complex applications like Byte Buds.'
        },
        

        {
            id: 4,
            src: jaImage,
            name: 'Juxta\'s Adventure',
            date: '21/06/2021',
            technologies: '[Python Pygame]',
            link: 'https://www.youtube.com/watch?v=LdNTxBX94aM',
            code: 'https://github.com/joshbates03/Juxtas-Adventure',
            description: 'Juxta\'s Adventure was created as part of my A-Level project in college using Pygame. This game was driven by a desire to increase replayability and player engagement. Juxtas Adventure allows users to design and play their own levels, providing a highly customizable and ever-evolving gaming experience. By integrating academic knowledge with practical development skills, Juxtas Adventure offers a dynamic and user-friendly platform that enhances creativity and problem-solving abilities for players of all ages.'

        },
    ];

    const handleExpandClick = (project) => {
        setSelectedProject(project);
    };

    const handleClosePopup = () => {
        setSelectedProject(null);
    };

    useEffect(() => {
        if (selectedProject) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, [selectedProject]);

    return (
        <div id="portfolio" className="w-full h-full py-10 text-white">
            <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
                <div className="pb-8">
                    <p className="text-4xl font-ubuntu font-bold text-black dark:text-white inline border-b-4 border-purple-500 dark:border-gray-800">Portfolio</p>
                    <p className="py-6 text-gray-800 dark:text-white font-ubuntu font-light">Below, you can view some of my projects. This section will be updated upon completion of a new project.</p>
                </div>

                <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-4 sm:px-0">
                    {portfolios.map((project) => (
                        <div key={project.id} className="bg-white dark:bg-gray-800 shadow-md dark:shadow-lg rounded-lg p-4 flex flex-col justify-between">
                            <img
                                src={project.src}
                                alt={project.name}
                                className="rounded-md duration-200 shadow-md hover:scale-105 mb-4"
                            />
                            <div className='flex flex-col items-center mb-4'>
                                <h1 className='text-xl text-black dark:text-white font-ubuntu font-light mb-2 text-center'>{project.name}</h1>
                                <button
                                    className='text-black dark:text-white font-ubuntu font-light hover:scale-105 hover:font-bold duration-200'
                                    onClick={() => handleExpandClick(project)}
                                >
                                    &lt;more details&gt;
                                </button>
                            </div>

                            <div className="flex items-center justify-between mt-5">
                                <button
                                    className='bg-blue-500 dark:bg-blue-600 text-white font-bold py-2 px-4 rounded-full duration-200 hover:bg-blue-600 hover:dark:bg-blue-700 hover:scale-105'
                                    onClick={() => window.location.href = project.link}
                                >
                                    {project.id === 4 ? 'Video' : 'Demo'}
                                </button>
                                <button
                                    className='bg-green-500 dark:bg-green-600 text-white font-bold py-2 px-4 rounded-full duration-200 hover:bg-green-600 hover:dark:bg-green-800 hover:scale-105'
                                    onClick={() => window.open(project.code, '_blank')}
                                >
                                    Code
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {selectedProject && (
                <div className='fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center p-4 overflow-auto'>
                    <div className='bg-white dark:bg-gray-800 rounded-lg shadow-lg p-5 w-full max-w-md'>
                        <img
                            src={selectedProject.src}
                            alt={selectedProject.name}
                            className="rounded-md duration-200 shadow-lg hover:scale-105 mb-4"
                        />
                        <div className='flex flex-col items-center mb-4'>
                            <h1 className='text-2xl text-black dark:text-white font-ubuntu font-medium mb-2'>{selectedProject.name}</h1>
                            <p className='text-gray-700 dark:text-gray-400 mb-2'>{selectedProject.technologies}</p>
                            <h2 className='text-l text-black dark:text-gray-400 font-ubuntu font-light mb-2'>{selectedProject.date}</h2>
                            <p className="text-black dark:text-white font-ubuntu font-extralight leading-relaxed mb-4">{selectedProject.description}</p>
                        </div>
                        <div className="flex items-center justify-center">
                            <button
                                onClick={handleClosePopup}
                                className='bg-red-500 dark:bg-red-700 text-white font-ubuntu font-medium py-2 px-4 rounded-full duration-200 hover:bg-red-700 hover:dark:bg-red-800 hover:scale-105'
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Portfolio;
